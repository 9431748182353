import {
  http,
  fallback,
  webSocket,
  createConfig,
  createStorage,
  cookieStorage,
  useConnectorClient,
} from "wagmi";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { mainnet, bsc, bscTestnet } from "wagmi/chains";
import { walletConnect, injected, coinbaseWallet } from "wagmi/connectors";

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

const metadata = {
  name: "Intelliverse ICPTG",
  description:
    "A suite of WEB3 tools based on AI technology, allow to launch and grow blockchain-powered projects efficiently.",
  image: "https://intelliverse-ai.com/logo/Logo460x460.png",
  url: "https://intelliverse-ai.com/website",
  twitter: "https://twitter.com/ai_icptg",
  icons: ["https://intelliverse-ai.com/logo/Logo460x460.png"],
};

export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID || "";

export type TSwitchChainIds = 1 | 56 | 97;

export const config = createConfig({
  chains:
    process.env.NODE_ENV === "development"
      ? [mainnet, bsc, bscTestnet]
      : [mainnet, bsc],
  ssr: true,
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: fallback([
      // Mnur&Intelliverse
      http(process.env.NEXT_PUBLIC_BSC_NODE_1_HTTP),
      webSocket(process.env.NEXT_PUBLIC_BSC_NODE_1_WS),
      // intelliverse node 2
      http(process.env.NEXT_PUBLIC_BSC_NODE_2_HTTP),
      webSocket(process.env.NEXT_PUBLIC_BSC_NODE_2_WS),
    ]),
    [bscTestnet.id]: http(),
  },
  connectors: [walletConnect({ projectId, metadata, showQrModal: false })],
  storage: createStorage({
    storage: cookieStorage,
  }),
});
