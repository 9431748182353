import { RootState } from "@/lib/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  timesUp: false,
  currentPhase: 0,
  saleIsActive: false,
  currencyInput: 0.2,
  tokensAmount: "0",
  bnbPrice: "0",
  phases: ["0.10", "0.15", "0.20", "0.25", "0.30"],
  selectedCurrency: "bnb",
  affiliateCode: "",
  buyBtnActive: true,
};

export const privateSaleSlice = createSlice({
  name: "privateSale",
  initialState,
  reducers: {
    setTimesUp: (state, action: PayloadAction<boolean>) => {
      state.timesUp = action.payload;
    },
    setCurrentPhase: (state, action: PayloadAction<number>) => {
      state.currentPhase = action.payload;
    },
    setSaleIsActive: (state, action: PayloadAction<boolean>) => {
      state.saleIsActive = action.payload;
    },
    setCurrencyInput: (state, action: PayloadAction<number>) => {
      state.currencyInput = action.payload;
    },
    setBNBPrice: (state, action: PayloadAction<string>) => {
      state.bnbPrice = action.payload;
    },
    setSelectedCurrency: (state, action: PayloadAction<string>) => {
      state.selectedCurrency = action.payload;
    },
    setAffiliateCode: (state, action: PayloadAction<string>) => {
      state.affiliateCode = action.payload;
    },
    setTokensAmount: (state, action: PayloadAction<string>) => {
      state.tokensAmount = action.payload;
    },
    setBuyBtnActive: (state, action: PayloadAction<boolean>) => {
      state.buyBtnActive = action.payload;
    },
  },
});

export const selectTimesUp = (state: RootState) => state.privateSale.timesUp;
export const selectCurrentPhase = (state: RootState) =>
  state.privateSale.currentPhase;
export const selectSaleIsActive = (state: RootState) =>
  state.privateSale.saleIsActive;
export const selectPhases = (state: RootState) => state.privateSale.phases;
export const selectCurrencyInput = (state: RootState) =>
  state.privateSale.currencyInput;
export const selectBNBPrice = (state: RootState) => state.privateSale.bnbPrice;
export const selectSelectedCurrency = (state: RootState) =>
  state.privateSale.selectedCurrency;
export const selectAffiliateCode = (state: RootState) =>
  state.privateSale.affiliateCode;
export const selectTokensAmount = (state: RootState) =>
  state.privateSale.tokensAmount;
export const selectBuyBtnActive = (state: RootState) =>
  state.privateSale.buyBtnActive;

export const {
  setTimesUp,
  setCurrentPhase,
  setSaleIsActive,
  setCurrencyInput,
  setBNBPrice,
  setSelectedCurrency,
  setAffiliateCode,
  setTokensAmount,
  setBuyBtnActive,
} = privateSaleSlice.actions;

export default privateSaleSlice.reducer;
