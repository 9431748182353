"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { State, WagmiProvider } from "wagmi";
import { config, projectId } from "@/wagmi/config";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { bsc } from "viem/chains";

const queryClient = new QueryClient();

createWeb3Modal({
  wagmiConfig: config,
  projectId: projectId,
  allowUnsupportedChain: false,
  enableAnalytics: true,
  defaultChain: bsc,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
});

const ContextProvider = ({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState?: State;
}) => (
  <WagmiProvider config={config} initialState={initialState}>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  </WagmiProvider>
);

export default ContextProvider;
